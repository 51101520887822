.imgContainer {
  /* width: 60%; */
  grid-area: im;
  overflow: hidden;
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.notImgContainer {
  grid-area: c;

  height: 100%;
  min-width: 0;
  min-height: 0;
}

.pContainer {
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-template-areas: "im im im im im im c c c c";
  width: 100%;

  height: 100%;
  min-width: 0;
  min-height: 0;
}

.notImgContainer .content {
  position: relative;
  /* padding: 10px 20px; */
  height: 100%;
  /* width: 100%; */
  overflow: auto;

  display: grid;
  grid-gap: 0px;

  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    "header"
    "title"
    "main";
}

.title {
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title h1 {
  text-align: center;
  margin: 20px 30px 0px 30px;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.date p {
  margin: 0px;
  color: var(--ark-grey);
}

.main {
  padding: 0px 20px;
}

.cHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0px;
}

.mHolder {
  margin: 20px 0px 0px 0px;
}

@media only screen and (max-width: 800px) {
  .pContainer {
    display: block;
  }

  .imgContainer {
    width: 100%;
    height: 50vh;
    border-bottom: 10px solid black;
  }

  .notImgContainer {
    width: 100%;
    height: max-content;
  }
}
