.background {
  top: -10%;
  left: -50%;
  width: 200%;
  height: 200%;
  position: absolute;
}

.parent {
  position: relative;
}

.backgroundParent {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}

.background.animate {
  animation: moveBackground 3000s linear infinite;;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 10000px 2000px; /* Adjust the 1000px as needed */
  }
}
