.cardWrapper {
  pointer-events: auto;
  border: 1px solid var(--grey);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(7px);
  transition: 0.25s;
  margin: 5px;
  overflow: hidden;
}

.cardWrapperHover:hover {
  transform: scale(1.05);
}

.clickable {
  cursor: pointer;
}

.cardWrapperHover:hover::after {
  z-index: 20;
  pointer-events: none;
  top: 0px;
  left: 0px;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 0, 255, 0) 80%
  );
  animation: shimmer 1.15s 1 linear;
  transform: translateX(400%);
}
@keyframes shimmer {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(400%);
  }
}
