.centeredCntainer li a {
  display: block;
  color: rgb(51, 51, 51);
  text-align: center;
  margin: 20px 16px;
  text-decoration: none;
}

.centeredCntainer li {
    z-index: 3;
    list-style-type: none
}

.centeredCntainer:hover .animContainer img {
    width: 100px;
}

.animContainer img {
    /* background-color: var(--gold); */
    transition: 0.1s;
    transform: rotate(-7deg);
    width: 0px;
    height: 21px;
}

.centeredCntainer {
    cursor: pointer;
    display: flex;
    align-items: center; /* vertical centering if flex-direction: row */
    justify-content: center; /* horizontal centering if flex-direction: row */
}

.animContainer {
    display: flex;
    position: absolute;
    margin: auto;
}