.parent {
    display: grid;
    grid-template-columns: 2fr 7fr;
    grid-template-rows: min-content;
}

.imCont {
    height: 0;
    min-height: 100%;
    overflow: hidden;
}

.imHolder {
    overflow: hidden;
    height: 100%;
}

.topCont {
    height: fit-content;
    padding: 10px 20px;
}

.title {
    margin: 0px;
}

.content {
    margin: 0px 20px;
}

.content > p > a {
    color: var(--grey)
}