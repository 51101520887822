.button {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.button img {
    transition: opacity .5s;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(.5);
}

.button:hover .blur{
    backdrop-filter: none;
}

.button:hover .titleContainer .hideTitle {
    opacity: .5;
}

.button img.hide {
    opacity: 0;
}

.titleContainer {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 100%;
    z-index: 3;
    text-align: center;

    transition: .4s;
}

.blur {
    backdrop-filter: blur(10px);
}

.title {
    transition: opacity .4s;
    color: var(--almost-black)
}

.parent {
    display: flex;
    flex-direction: column;
    align-items: center;
}