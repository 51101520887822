.parent {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px;
}

.parent p {
    margin: 5px 0px;
    color: var(--almost-black);
}

.right {
    text-align: end;
}