.pContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.imgContainer {
  width: 60%;
  overflow: hidden;
  height: 100%;
}

.notImgContainer {
  width: 40%;
}

.notImgContainer .content {
  padding: 10px 20px;
  height: calc(100% - 20px);
  width: calc(100% - 40px);

  display: grid;
  grid-gap: 0px;

  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "header"
    "title"
    "main"
    "footer";
}

.title {
  grid-area: title;
  margin: 0px;
}

.footer {
  grid-area: footer;
}

.lr {
  grid-area: header;
}

.main {
  margin-top: 5px;
  grid-area: main;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main::-webkit-scrollbar {
  display: none;
}
