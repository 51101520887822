.buttons {
    display: none;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - 20px);
    transition: .5s;
    z-index: 3;
}

.parent:hover .buttonsShow {
    display: flex;
}

.parent:hover .captionHolder{
    top: auto;
    bottom: 10px;
}

.parent {
    display: flex;
    align-items: center; /* vertical centering if flex-direction: row */
    justify-content: center; /* horizontal centering if flex-direction: row */
    height: 100%;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    backdrop-filter: blur(15px);
}

.parent::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(7px);
}

.parent img {
    z-index: 2;
    min-height: 50px;
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
}

.button {
    cursor: pointer;
    height: 50px;
    width: 25px;
    display: flex;
    align-items: center; /* vertical centering if flex-direction: row */
    justify-content: center; /* horizontal centering if flex-direction: row */
}

.captionHolder {
    transition: all .5s;
    position: absolute;
    z-index: 3;
    /* top: 100%; */
    bottom: -100px;
    max-width: calc(100% - 20px);
    max-height: 100px;
}

.captionSpacer > p {
    margin: 10px;
}